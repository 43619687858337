'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import clx from 'classnames';

import { useSetMainMenuOpenContext } from '../../../../../data/context/SiteNavigationContext';

import Button from '../../../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../../../_ui/_blocks/Icon/Icon';

import styles from '../StickyHeader.module.css';

const MainMenuButton = () => {
    const pathName = usePathname();

    const setSidebarNavIsOpen = useSetMainMenuOpenContext();
    const handleSidebarOpen = () => setSidebarNavIsOpen(true);

    const isAccount = !!pathName?.includes('/account');

    const classNames = clx(
        styles.link,
        // If it is not the account page, hide on mobile.
        !isAccount ? 'lg:!hidden' : '',
    );

    return (
        <Button
            variant="none"
            aria-label="Open the main menu"
            title="Open the main menu"
            onClick={handleSidebarOpen}
            className={classNames}
        >
            <Icon icon="menu-dark" size="medium" alt="Menu" loading="eager" />
        </Button>
    );
};

export default MainMenuButton;
