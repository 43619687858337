import React from 'react';
import clx from 'classnames';

import { useIsPanelInertContext, useIsPanelOpenContext } from '../DrillDownPanelContext/DrillDownContext';

import ButtonLink from '../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Typography from '../../../../_ui/_blocks/Typography/Typography';

import styles from './Panel.module.css';

interface Props {
    title?: string;
    isDropdown?: boolean;
    isTopLevelMenu?: boolean;
    className?: string;
    calloutButtonHref?: string;
    calloutButtonText?: string;
    calloutButtonVariant?: React.ComponentProps<typeof ButtonLink>['variant'];
    children: React.ReactNode;
}

const Panel = ({
    title,
    isTopLevelMenu,
    calloutButtonHref,
    calloutButtonText,
    calloutButtonVariant,
    children,
    className,
    isDropdown,
}: Props) => {
    const isOpen = useIsPanelOpenContext();
    const isInert = useIsPanelInertContext();

    const Tag = isTopLevelMenu ? 'div' : 'nav';

    const classNames = clx(
        className,
        isTopLevelMenu ? styles.topLevelPanel : styles.panel,
        isOpen ? styles.panelOpen : '',
    );

    return (
        <Tag
            className={classNames}
            aria-label={title}
            data-dd-submenu={!isTopLevelMenu ? true : undefined}
            data-dd-menu={isTopLevelMenu ? true : undefined}
            data-dd-dropdown={isDropdown}
            // @ts-expect-error
            inert={isInert ? 'true' : undefined}
        >
            <div className="flex-grow overflow-auto">
                {title ? (
                    <Typography
                        as="div"
                        typeset="heading"
                        size="100"
                        lineHeight="200"
                        aria-hidden="true"
                        className={styles.title}
                        data-dd-submenu-title
                    >
                        {title}
                    </Typography>
                ) : null}
                {children}
            </div>
            {calloutButtonHref && calloutButtonText ? (
                <div data-dd-submenu-callout className={styles.callout}>
                    <ButtonLink href={calloutButtonHref} variant={calloutButtonVariant}>
                        {calloutButtonText}
                    </ButtonLink>
                </div>
            ) : null}
        </Tag>
    );
};

export default Panel;
