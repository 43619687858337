'use client';

import React, { useState, useMemo } from 'react';

import fireGoogleAnalyticsEvent from '../../../Tracking/Google/fireGoogleAnalyticsEvent';
import { QUANTITY_TYPES } from '../../../../constants';
import { BasketItem as BasketItemType } from '../../../../types/api/basket/BasketItem';

import DefaultBasketItem from './ItemTypes/DefaultBasketItem/DefaultBasketItem';
import UnavailableBasketItem from './ItemTypes/UnavailableBasketItem/UnavailableBasketItem';
import OutOfStockBasketItem from './ItemTypes/OutOfStockBasketItem/OutOfStockBasketItem';
import QuantityUnavailableBasketItem from './ItemTypes/QuantityUnavailableBasketItem/QuantityUnavailableBasketItem';
import BasketQuantitySelector from '../../BasketQuantitySelector/BasketQuantitySelector';

const componentIndex = {
    default: DefaultBasketItem,
    unavailable: UnavailableBasketItem,
    out_of_stock: OutOfStockBasketItem,
    quantity_unavailable: QuantityUnavailableBasketItem,
};

export interface Props {
    item: BasketItemType;
}

/**
 * Basket Item component selector.
 * Takes a component index and displays the correct item based upon the basketitemtype provided.
 * This also does some work to allow quanity change to have some extra values passed in for each item.
 */
const BasketItem = ({ item }: Props) => {
    const [itemToUpdate, setItemToUpdate] = useState<BasketItemType | null>(null);

    const openQuantitySelector = () => setItemToUpdate(item);
    const closeQuantitySelector = () => setItemToUpdate(null);

    const selectedQuantity = useMemo(() => {
        if (!item) return null;

        if (!item.quantities.length) return null;

        if (item.quantity_type.toUpperCase() === QUANTITY_TYPES.timed) return item.quantities[0];

        return item.quantities.find((q) => q.quantity.toString() === item.quantity.toString());
    }, [item]);

    const basketItemType = useMemo(() => {
        if (!item) return null;

        if (!item.available) return 'unavailable';
        if (!item.quantities.length) return 'out_of_stock';
        if (item.quantity_type.toUpperCase() === QUANTITY_TYPES.regular && !selectedQuantity) return 'quantity_unavailable';

        return 'default';
    }, [item, selectedQuantity]);

    const ComponentToRender = componentIndex[basketItemType!];
    const onSuccess = (newQuantity: { quantity: any }) => {
        fireGoogleAnalyticsEvent({
            event_category: 'courseOutOfStock',
            event_label: `Out of stock for ${item.treatment}, reduced sale from ${item.quantity} to ${newQuantity.quantity}`,
        });
    };

    if (!ComponentToRender) {
        return <p> This type is unavailable. There is an error with the site. Please contact support. </p>;
    }

    return (
        <li data-testid="basket-item">
            <ComponentToRender item={item} openQuantitySelector={openQuantitySelector} />
            <BasketQuantitySelector
                // @ts-expect-error
                itemToUpdate={itemToUpdate}
                onClose={closeQuantitySelector}
                // @ts-expect-error
                onSuccess={basketItemType === 'quantity_unavailable' ? onSuccess : null}
            />
        </li>
    );
};

export default BasketItem;
