import React from 'react';
import { usePathname } from 'next/navigation';
import clx from 'classnames';

import { NavigationType } from '../../Header/Header';
import { useDrillDownStackContext, useIsKeyboardFocusLogicDisabledContext } from '../DrillDownContext/DrillDownContext';
import { DrillDownPanelProvider } from './DrillDownPanelContext/DrillDownContext';

import DrillDownLink from './DrillDownLink/DrillDownLink';
import Panel from './Panel/Panel';
import ReorderList from './CustomMenu/ReorderList/ReorderList';
import CustomMenu, { getCustomMenuName, getNavChildType, shouldCustomMenuBeShown } from './CustomMenu/CustomMenu';

import styles from './DrillDownPanel.module.css';

type PanelProps = Omit<React.ComponentProps<typeof Panel>, 'children' | 'className'>;
type CustomMenuProps = Pick<React.ComponentProps<typeof CustomMenu>, 'treatmentCount' | 'conditionCount'>

interface Props extends PanelProps, CustomMenuProps {
    navItems: NavigationType['items'];
    isDropdown?: boolean;
    children?: React.ReactNode;
    parentNavKey?: NavigationType['items'][number]['_key'];
    isMainMenu?: boolean;
}

const DrillDownPanel = ({
    navItems,
    isDropdown,
    children,
    parentNavKey,
    isMainMenu,
    title,
    treatmentCount,
    conditionCount,
    isTopLevelMenu,
    calloutButtonHref,
    calloutButtonText,
    calloutButtonVariant,
}: Props) => {
    const pathName = usePathname();
    const drillDownStack = useDrillDownStackContext();
    const isKeyboardFocusLogicDisabled = useIsKeyboardFocusLogicDisabledContext();

    // If keyboard focus logic isn't disabled, check if one of the nav items is in the stack. If it is,
    // disable all the links in this panel.
    const navItemKeys = navItems.map((navItem) => navItem._key);
    const hasNavItemOpen = !isKeyboardFocusLogicDisabled && !!drillDownStack.find((item) => (
        navItemKeys.includes(item)
    ));

    return (
        <DrillDownPanelProvider isTopLevelMenu={isTopLevelMenu} parentNavKey={parentNavKey}>
            <Panel
                title={title}
                isTopLevelMenu={isTopLevelMenu}
                isDropdown={isDropdown}
                calloutButtonHref={calloutButtonHref}
                calloutButtonText={calloutButtonText}
                calloutButtonVariant={calloutButtonVariant}
            >
                <ul>
                    {navItems.map((navItem) => {
                        const isDemoted = navItem.classes?.includes('demoted') || undefined;

                        const subMenuType = getNavChildType(navItem);

                        const customMenuName = getCustomMenuName(navItem.classes);
                        const showCustomMenu = shouldCustomMenuBeShown(customMenuName, pathName || '');

                        const hasSubMenu = (subMenuType === 'customMenu' && showCustomMenu) || subMenuType === 'dropdown';

                        // Render the dropdown icon if the nav item has some kind of sub menu or it has a custom name.
                        const renderSubMenuIcon = hasSubMenu || !!customMenuName;

                        // Make icon invisible in CSS if it has a custom menu but its disabled. This is to reduce layout shift.
                        const makeSubMenuIconInvisible = !showCustomMenu && !!customMenuName;

                        const listItemClasses = clx(
                            styles.listItem,
                            isTopLevelMenu ? styles.listItemPaddingSmall : styles.listItemPaddingLarge
                        );

                        return (
                            <li key={navItem._key} className={listItemClasses} data-demoted={isDemoted}>
                                <DrillDownLink
                                    navItem={navItem}
                                    isTopLevelMenu={isTopLevelMenu}
                                    isMainMenu={isMainMenu}
                                    disabled={!!hasNavItemOpen}
                                    hasSubMenu={hasSubMenu}
                                    renderSubMenuIcon={renderSubMenuIcon}
                                    makeSubMenuIconInvisible={makeSubMenuIconInvisible}
                                />
                                {subMenuType === 'reorders' ? <ReorderList /> : null}
                                {subMenuType === 'customMenu' && showCustomMenu ? (
                                    <CustomMenu
                                        navItem={navItem}
                                        parentNavKey={navItem._key}
                                        treatmentCount={treatmentCount}
                                        conditionCount={conditionCount}
                                    />
                                ) : null}
                                {subMenuType === 'dropdown' ? (
                                    <DrillDownPanel
                                        isDropdown
                                        title={navItem.title}
                                        treatmentCount={treatmentCount}
                                        conditionCount={conditionCount}
                                        navItems={navItem.children || []}
                                        isMainMenu={isMainMenu}
                                        parentNavKey={navItem._key}
                                    />
                                ) : null}
                            </li>
                        );
                    })}
                    {children}
                </ul>
            </Panel>
        </DrillDownPanelProvider>
    );
};

export default DrillDownPanel;
