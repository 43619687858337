import { useCallback, useEffect, useRef } from 'react';

import { getFirstFocusableDescendant } from '../../../../helpers/focusUtils';
import useKeepFocusWithin from '../../../../hooks/useKeepFocusWithin';
import useOnNavigate from '../../../../hooks/useOnNavigate';
import { useModifyDrillDownStackContext } from '../DrillDownContext/DrillDownContext';

/**
 * Toggles the overflow property on the body tag.
 */
const toggleBodyOverflow = (isOpen: boolean) => {
    if (typeof window !== 'undefined') {
        if (isOpen) {
            document.body.style.setProperty('overflow', 'hidden');
        } else {
            document.body.style.removeProperty('overflow');
        }
    }
}

/**
 * Manages the focus state for the drill-down, overflow state and clean up when it's closed.
 */
const useDrillDown = (drillDownElement: HTMLElement | null, onClose: () => void, isOpen?: boolean) => {
    const previouslyFocusedElement = useRef<HTMLElement | null>(null);

    const { clearStack } = useModifyDrillDownStackContext();

    // On close, close the drill-down and clear the stack so the stack starts fresh when opened again.
    const handleClose = useCallback(() => {
        onClose();
        clearStack();
    }, [onClose, clearStack]);

    // Toggle the overflow state on the body when the drill-down is opened and closed.
    useEffect(() => {
        toggleBodyOverflow(!!isOpen);
    }, [isOpen]);

    // Close the drill-down when the page navigates.
    useOnNavigate(handleClose);

    // Sets the previously focused element, switches back to it when closed.
    useEffect(() => {
        if (typeof window === 'undefined' || !drillDownElement) {
            return;
        }

        if (isOpen) {
            previouslyFocusedElement.current = document.activeElement as HTMLElement | null;
            getFirstFocusableDescendant(drillDownElement)?.focus();
        } else {
            previouslyFocusedElement.current?.focus();
        }
    }, [isOpen, drillDownElement]);

    // Keeps the focus within the drill-down.
    useKeepFocusWithin(drillDownElement, {
        disabled: !isOpen
    });

    return {
        handleClose,
    }
};

export default useDrillDown;
