import React from 'react';

import Typography from '../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    closeBasket: () => void;
}

const BasketEmpty = ({ closeBasket }: Props) => (
    <div className="text-center p-10 px-300 bg-layers-white">
        <Typography as="h2" typeset="subtitle" size="300" className="block">
            Empty basket
        </Typography>
        <Typography as="p" size="090" className="mt-025">
            There's nothing in your basket. Find your ideal treatment to get started.
        </Typography>
        <ButtonLink href="/treatments" className="w-[13rem] mt-100 mx-auto" onClick={closeBasket}>
            Find your treatment
        </ButtonLink>
        <ButtonLink
            variant="tertiary"
            href="/conditions"
            className="w-[13rem] mt-100 mx-auto"
            onClick={closeBasket}
        >
            Find your condition
        </ButtonLink>
    </div>
);

export default BasketEmpty;
