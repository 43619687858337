import React, { Fragment, useRef } from 'react';
import clx from 'classnames';

import { DrillDownProvider, useIsKeyboardFocusLogicDisabledContext } from './DrillDownContext/DrillDownContext';
import useDrillDown from './hooks/useDrillDown';

import DrillDownMask from './DrillDownMask/DrillDownMask';
import DrillDownHeader from './DrillDownHeader/DrillDownHeader';
import DrillDownPanel from './DrillDownPanel/DrillDownPanel';

import styles from './DrillDown.module.css';

type DrillDownHeaderProps = React.ComponentProps<typeof DrillDownHeader>;
type DrillDownPanelProps = Omit<
    React.ComponentProps<typeof DrillDownPanel>,
    'isTopLevelMenu' | 'title' | 'inert' | 'data-dd-dropdown' | 'parentNavKey'
>;

interface DrillDownContentProps extends DrillDownHeaderProps, DrillDownPanelProps {
    className?: string;
    label?: string;
    isOpen?: boolean;
}

/**
 * Root drill-down component.
 */
const DrillDownContent = ({ title, label, className, onClose, isOpen, isMainMenu, ...rest }: DrillDownContentProps) => {
    // Manages the focus for the drill-down.
    const navRef = useRef(null);

    const isKeyboardFocusLogicDisabled = useIsKeyboardFocusLogicDisabledContext();

    // Hook to manage the main focus state and closing logic.
    const { handleClose } = useDrillDown(
        navRef.current,
        onClose,
        isKeyboardFocusLogicDisabled ? false : isOpen,
    );

    const classNames = clx(
        '_js-nav-menu',
        styles.nav,
        isOpen ? styles.navOpen : '',
        className
    );

    // If the keyboard focus logic isn't disabled and the drill-down item isn't open.
    const isInert = !isKeyboardFocusLogicDisabled && !isOpen ? 'true' : undefined;

    return (
        <Fragment>
            <DrillDownMask isOpen={isOpen} onClose={handleClose} />
            <nav
                ref={navRef}
                className={classNames}
                aria-label={label}
                // @ts-expect-error - Added this here as typescript doesn't match up with react when using inert.
                inert={isInert}
                aria-hidden={!isOpen}
            >
                <DrillDownHeader title={title} onClose={handleClose} />
                <DrillDownPanel
                    // This is the top level panel, it has slightly different styling and rules so need to
                    // mark the panel as the top level panel.
                    isTopLevelMenu
                    isMainMenu={isMainMenu}
                    {...rest}
                />
            </nav>
        </Fragment>
    );
};

interface DrillDownProps extends DrillDownContentProps {
    isKeyboardFocusLogicDisabled?: boolean;
}

const DrillDown = ({ isKeyboardFocusLogicDisabled = false, ...rest }: DrillDownProps) => (
    <DrillDownProvider isKeyboardFocusLogicDisabled={isKeyboardFocusLogicDisabled}>
        <DrillDownContent {...rest} />
    </DrillDownProvider>
);

export default DrillDown;
