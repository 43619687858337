import { useEffect, useState } from 'react';

/**
 * If the code is running client side.
 */
const useIsClient = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsClient(true);
        }
    }, []);

    return isClient;
};

export default useIsClient;
