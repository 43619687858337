import React from 'react';

import formatQuantityLabel from '../../../../../Treatment/helpers/formatQuantityLabel';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import ChevronRight from '../../../../../_ui/_blocks/Icon/IconComponents/ChevronRight';

import styles from './QuantityButton.module.css';

interface Props {
    onClick: (args: any) => void;
    item: BasketItem;
    className?: string;
}

const QuantityButton = ({ onClick, item, className }: Props) => {
    if (item.consultation?.type === 'REFILL') {
        return (
            <Typography as="div" size="070" className={className}>
                {`${formatQuantityLabel(item)} (Refill)`}
            </Typography>
        );
    }

    return (
        <button
            type="button"
            className={`${styles.button} ${className || ''}`}
            onClick={onClick}
            title={`Change quantity of ${item.treatment}`}
        >
            <span className="flex-grow ellipsis">{formatQuantityLabel(item)}</span>
            <ChevronRight className="fill-actions-secondary rotate-90" />
        </button>
    );
};

export default QuantityButton;
