'use client';

import React from 'react';
import dynamic from 'next/dynamic';

import { useBasketContext } from '../../../../../data/context/basketContext/basketContext';

import Button from '../../../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../../../_ui/_blocks/Icon/Icon';

import styles from '../StickyHeader.module.css';

const BasketCount = dynamic(() => import('./BasketCount/BasketCount'), {
    ssr: false,
});

/**
 * Button in the site header to open the basket. Had to do this to have use client inside.
 */
const BasketButton = () => {
    // @ts-expect-error
    const { basket, setBasketIsOpen } = useBasketContext();

    const handleOpenBasket = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setBasketIsOpen((s: boolean) => !s);
    };

    return (
        <Button
            variant="none"
            aria-label="Open your basket"
            title="Open your basket"
            onClick={handleOpenBasket}
            className={styles.link}
        >
            <span className="hidden lg:inline">Basket</span>
            <span className="relative">
                <BasketCount basket={basket} />
                <Icon icon="basket-dark" size="medium" className="relative" alt="Basket" loading="eager" />
            </span>
        </Button>
    );
};

export default BasketButton;
