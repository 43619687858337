/* eslint-disable consistent-return */

import { useEffect } from 'react';

import { getFirstFocusableDescendant } from '../helpers/focusUtils';

interface Options {
    disabled?: boolean;
}

/**
 * Keeps focus within a specific element.
 */
const useKeepFocusWithin = (element: HTMLElement | null, options: Options = {}) => {
    useEffect(() => {
        if (typeof window === 'undefined' || !element || options.disabled) {
            return;
        }

        const handleFocusChange = (e: FocusEvent) => {
            if (e.target && element.contains(e.target as Node) === false) {
                e.preventDefault();
                getFirstFocusableDescendant(element)?.focus();
            }
        }

        window.addEventListener('focus', handleFocusChange, true);

        return () => {
            window.removeEventListener('focus', handleFocusChange, true);
        }
    }, [element, options.disabled])
}

export default useKeepFocusWithin;
