import React, { useEffect, useState, useRef } from 'react';

import dayjs from '@/lib/dayjs/dayjs';

interface ChildParams {
    hours: number;
    minutes: number;
    seconds: number;
    hasElapsed: boolean;
}

interface Props {
    countDownTo: string; // In "YYYY-MM-DD HH:mm:ss" format.
    onExpire: () => void;
    children: (params: ChildParams) => React.ReactNode;
}

/**
 * Calculates the difference between now and the end time.
 */
const getDuration = (countDownTo: Props['countDownTo']) => {
    const endTime = dayjs(countDownTo, 'YYYY-MM-DD HH:mm:ss');
    return dayjs.duration(endTime.diff(dayjs()));
};

/**
 * Displays a countdown with hours minutes and seconds.
 */
const Countdown = ({ countDownTo, onExpire, children }: Props) => {
    const interval = useRef<ReturnType<typeof setInterval> | null>(null);
    const initialRender = useRef(true);

    // Create state to store the duration.
    const [duration, setDuration] = useState(getDuration(countDownTo));

    // Get the hours minutes and seconds from the duration in state.
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = Math.floor(duration.asMinutes() % 60);
    const seconds = Math.floor(duration.asSeconds() % 60);

    const hasElapsed = (hours + minutes + seconds) <= 0;

    // If the countDownTo time changes, clear and re-start the interval.
    useEffect(() => {
        // Skips these bits on initial render.
        if (initialRender.current === false) {
            if (interval.current) {
                clearInterval(interval.current);
            }

            setDuration(getDuration(countDownTo));
        }

        interval.current = setInterval(() => {
            setDuration(getDuration(countDownTo));
        }, 1000);

        initialRender.current = false;
    }, [countDownTo]);

    // If count down time has elapsed.
    useEffect(() => {
        if (hasElapsed) {
            if (interval.current) {
                clearInterval(interval.current);
            }
            onExpire();
        }
    }, [hasElapsed, onExpire]);

    return children({
        hours,
        minutes,
        seconds,
        hasElapsed,
    });
};

export default Countdown;
