import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

interface Config {
    disabled?: boolean;
}

const useScrollPosition = (config: Config = {}) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (typeof window === 'undefined' || config.disabled) {
            setScrollPosition(0);
            return () => {};
        }

        const handleScroll = throttle(() => {
            setScrollPosition(window.scrollY)
        }, 200);

        document.addEventListener('scroll', handleScroll);

        return () => {
            handleScroll.cancel();
            document.removeEventListener('scroll', handleScroll);
        }
    }, [config.disabled]);

    return scrollPosition;
};

export default useScrollPosition;
