import React, { Fragment } from 'react';
import clx from 'classnames';

import { NavigationType } from '../../../Header/Header';
import { useModifyDrillDownStackContext } from '../../DrillDownContext/DrillDownContext';

import Icon from '../../../../_ui/_blocks/Icon/Icon';
import NavigationLink from '../../../NavigationLink/NavigationLink';

interface Props {
    navItem: NavigationType['items'][number];
    isMainMenu?: boolean;
    isTopLevelMenu?: boolean;
    disabled?: boolean;
    hasSubMenu: boolean;
    renderSubMenuIcon: boolean;
    makeSubMenuIconInvisible: boolean;
}

const DrillDownLink = ({
    navItem,
    isTopLevelMenu,
    isMainMenu,
    hasSubMenu,
    disabled,
    renderSubMenuIcon,
    makeSubMenuIconInvisible,
}: Props) => {
    const { addToStack } = useModifyDrillDownStackContext();

    const isDemoted = navItem.classes?.includes('demoted') || undefined;
    const linkClassNames = clx(
        isTopLevelMenu ? 'py-075' : 'py-100',
        isDemoted ? '!type-paragraph !type-090' : '',
    );

    const handleDrillDownClick = () => {
        addToStack(navItem._key);
    }

    return (
        <Fragment>
            {hasSubMenu ? (
                <button
                    type="button"
                    aria-hidden="true"
                    onClick={handleDrillDownClick}
                    className={linkClassNames}
                    disabled={disabled}
                >
                    {navItem.title}
                    <Icon
                        icon="chevron-right-light"
                        size="xsmall"
                        alt=""
                        aria-hidden="true"
                        loading="eager"
                    />
                </button>
            ) : null}
            {isMainMenu || !hasSubMenu ? (
                <NavigationLink
                    navItem={navItem}
                    className={linkClassNames}
                    partiallyActive
                    tabIndex={disabled ? -1 : undefined}
                >
                    {navItem.title}
                    {renderSubMenuIcon ? (
                        <Icon
                            icon="chevron-down-light"
                            size="xxsmall"
                            alt=""
                            aria-hidden="true"
                            loading="eager"
                            className={`hidden lg:block mt-025 ${makeSubMenuIconInvisible ? 'invisible' : ''}`}
                        />
                    ) : null}
                </NavigationLink>
            ) : null}
        </Fragment>
    );
};

export default DrillDownLink;
