import React from 'react';

import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import Tag from '../../../../../_ui/_blocks/Tag/Tag';
import ImageWithFallback from '../../../../../NextJs/ImageWithFallback/ImageWithFallback';

import styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
}

const OutOfStockBasketItem = ({ item }: Props) => {
    const { deleteItems } = useManageBasketItems();

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={() => deleteItems([{ id: item.id }])}
        >
            <div data-testid="outofstock_basket_item" className="flex items-start gap-075">
                <ImageWithFallback
                    fallback="/images/pill.svg"
                    width={52}
                    height={52}
                    className={styles.image}
                    src={!Array.isArray(item.public) && item.public ? item.public.images.featuredUrl : null}
                    alt={item.treatment}
                />
                <div className="flex-grow">
                    <div className='flex justify-between items-start'>
                        <Tag size="medium" color="error">Out of stock</Tag>
                        <DeleteCardOverlayTrigger
                            title={`Remove ${item.treatment} from your basket`}
                            className="text-status-error-default"
                        />
                    </div>
                    <Typography as="h6" typeset="emphasis" size="070" className="mt-025">
                        {item.treatment}
                    </Typography>
                    <Typography as="p" size="070">
                        This item is out of stock and will be removed from your order on checkout.
                    </Typography>
                </div>
            </div>
        </DeleteCardOverlay>
    );
};

export default OutOfStockBasketItem;
