import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

interface Config {
    disabled?: boolean;
    tolerance?: number; // How far the scroll must have gone (in px) before deciding direction has changed. Default in code is 30.
}

const getScrollDirection = (startingScrollPosition: number, newScrollPosition: number) => {
    if (typeof window === 'undefined') {
        return 'static';
    }

    if (startingScrollPosition > newScrollPosition) {
        return 'up';
    }

    if (startingScrollPosition < newScrollPosition) {
        return 'down';
    }

    return 'static';
}

interface OnScrollCallbackDetails {
    direction: ReturnType<typeof getScrollDirection>;
    scrollY: number;
}

export type OnScrollCallback = (details: OnScrollCallbackDetails) => void;

const useScrollDirection = (config: Config = {}) => {
    const [scrollDirection, setScrollDirection] = useState<ReturnType<typeof getScrollDirection>>('static');

    const previousScrollPosition = useRef(typeof window === 'undefined' ? 0 : window.scrollY);

    useEffect(() => {
        if (typeof window === 'undefined' || config.disabled) {
            setScrollDirection('static');
            return () => {};
        }

        const handleScroll = throttle(() => {
            const currentScrollPosition = window.scrollY;
            const tolerance = Number.isNaN(Number(config.tolerance)) ? 15 : config.tolerance!;

            if (Math.abs(currentScrollPosition - previousScrollPosition.current) > tolerance) {
                setScrollDirection(getScrollDirection(previousScrollPosition.current, currentScrollPosition))
            }

            previousScrollPosition.current = currentScrollPosition;
        }, 250);

        document.addEventListener('scroll', handleScroll);

        return () => {
            handleScroll.cancel();
            document.removeEventListener('scroll', handleScroll);
        }
    }, [config.disabled, config.tolerance]);

    return scrollDirection;
};

export default useScrollDirection;
