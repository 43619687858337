import React from 'react';

import { BasketItem } from '../../../../../../types/api/basket/BasketItem';
import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';

import Tag from '../../../../../_ui/_blocks/Tag/Tag';
import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import Icon from '../../../../../_ui/_blocks/Icon/Icon';

import styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
}

const UnavailableBasketItem = ({ item }: Props) => {
    const { deleteItems } = useManageBasketItems();

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={() => deleteItems([{ id: item.id }])}
        >
            <div data-testid="unavailable_basket_item" className="flex items-start gap-075">
                <div className={`${styles.image} ${styles.unavailableImage}`}>
                    <Icon icon='blocked-light' alt="" aria-hidden="true" />
                </div>
                <div className="flex-grow">
                    <div className='flex justify-between items-start'>
                        <Tag size="medium" color="error">Item unavailable</Tag>
                        <DeleteCardOverlayTrigger
                            title={`Remove ${item.treatment} from your basket`}
                            className="text-status-error-default"
                        />
                    </div>
                    <Typography
                        as="h6"
                        typeset="emphasis"
                        size="070"
                        lineThrough
                        className="mt-025"
                    >
                        {item.treatment}
                    </Typography>
                    <Typography as="p" size="070">
                        This item is no longer available and will be removed from your order on checkout.
                    </Typography>
                </div>
            </div>
        </DeleteCardOverlay>
    );
};

export default UnavailableBasketItem;
