import React from 'react';

import { useDrillDownStackContext, useModifyDrillDownStackContext } from '../DrillDownContext/DrillDownContext';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import Icon from '../../../_ui/_blocks/Icon/Icon';

import styles from './DrillDownHeader.module.css';

interface Props {
    title: string;
    closeIcon?: 'cross-light' | 'chevron-down-light';
    onClose: () => void;
}

const DrillDownHeader = ({ title, closeIcon = 'cross-light', onClose }: Props) => {
    const { popStack } = useModifyDrillDownStackContext();
    const drillDownStack = useDrillDownStackContext();

    return (
        <Typography
            as="div"
            typeset="subtitle"
            className={styles.header}
            size="200"
            lineHeight="200"
            aria-hidden="true"
            data-dd-header
        >
            <button
                type="button"
                disabled={!drillDownStack.length}
                onClick={popStack}
                className={`${styles.backButton} ${drillDownStack.length ? styles.backButtonActive : ''}`}
                aria-hidden={!drillDownStack.length}
            >
                <Icon
                    icon="chevron-left-light"
                    size="small"
                    alt=""
                />
                <span>
                    <span>Back</span>
                    <span>{title}</span>
                </span>
            </button>
            <button
                type="button"
                className="p-025 flex-shrink-0"
                onClick={onClose}
                title="Close menu"
            >
                <Icon
                    className="box-content"
                    icon={closeIcon}
                    size="small"
                    alt=""
                />
            </button>
        </Typography>
    );
};

export default DrillDownHeader;
