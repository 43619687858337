'use client';

import React, { Fragment, useState } from 'react';

import formatPrice from '../../../../helpers/formatPrice';

import SaveBasketConfirmation from '../SaveBasketConfirmation/SaveBasketConfirmation';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Tag from '../../../_ui/_blocks/Tag/Tag';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Hr from '../../../_ui/_blocks/Hr/Hr';
import Modal from '../../../_ui/Modal/Modal';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Flash from '../../../FlashMessage/Flash/Flash';

import styles from './BasketSummary.module.css';

export interface Props {
    basketSubtotal: number;
    minimumDeliveryCost: number;
    onClose: () => void;
}

// creating a variable for this to prevent it being hardcoded in a few places.
const FREE_SHIPPING_THRESHOLD = 4000;

const BasketSummary = ({ minimumDeliveryCost, basketSubtotal, onClose }: Props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    let basketTotal: string | number = basketSubtotal;

    // If the basket value is under £40 then we add the minimum delivery cost.
    if (basketSubtotal < FREE_SHIPPING_THRESHOLD) {
        basketTotal = basketSubtotal + (Number(minimumDeliveryCost) || 0);
    }

    // If the basket value is over £40 then we just format the basket total and return it.
    basketTotal = formatPrice(basketTotal);

    const handleSaveBasketOpen = () => setShowOverlay(true);
    const handleSaveBasketClose = () => setShowOverlay(false);

    return (
        <Fragment>
            <div>
                <button
                    data-testid="basket-save-for-later-button"
                    title="Save you basket for another time"
                    className={styles.saveForLaterButton}
                    onClick={handleSaveBasketOpen}
                >
                    Save basket for later
                </button>
            </div>
            <div className={styles.basketSummary}>
                <div className="flex justify-between items-center pt-100 px-100">
                    <Typography as="h6" typeset="heading" size="090" lineHeight="200">
                        Subtotal
                    </Typography>
                    <Typography typeset="emphasis" size="090" color="price">
                        {formatPrice(basketSubtotal)}
                    </Typography>
                </div>
                <Hr className="my-100" />
                <div className="flex items-center justify-between gap-100 px-100">
                    <Typography as="h6" typeset="heading" size="090" lineHeight="200">
                        Delivery estimate
                    </Typography>
                    {basketSubtotal > FREE_SHIPPING_THRESHOLD ? (
                        <Tag size="medium" color="info">Free</Tag>
                    ) : (
                        <Typography as="div" typeset="emphasis" size="090" color="price">
                            {formatPrice(minimumDeliveryCost)}
                        </Typography>
                    )}
                </div>
                {basketSubtotal < FREE_SHIPPING_THRESHOLD ? (
                    <Flash type="info" className="mt-100 mx-100">
                        Free standard delivery on orders over £40
                    </Flash>
                ) : null}
                <Hr className="my-100" />
                <div className="flex items-center justify-between gap-100 px-100 pb-100">
                    <Typography as="h6" typeset="heading" size="100" lineHeight="200">
                        Order total
                    </Typography>
                    <Typography as="div" typeset="emphasis" size="100" color="price" lineHeight="none">
                        {basketTotal}
                    </Typography>
                </div>
                <div className="bg-content-foundation p-100">
                    <ButtonLink
                        data-testid="basket-checkout-button"
                        className="w-full text-center"
                        title="Proceed to checkout"
                        href="/checkout"
                    >
                        Checkout
                    </ButtonLink>
                </div>
            </div>
            <Modal show={showOverlay} onClose={handleSaveBasketClose} className="max-w-md" zIndex="z-1200">
                <SaveBasketConfirmation onClose={handleSaveBasketClose} />
            </Modal>
        </Fragment>
    );
};

export default BasketSummary;
