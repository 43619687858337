/**
 * Checks if the element passed in can be focused on.
 */
export const isElementFocusable = (element: HTMLElement) => {
    const canElementBeFocused =
        element instanceof HTMLAnchorElement ||
        element instanceof HTMLButtonElement ||
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element instanceof HTMLSelectElement;

    // If its not one of the elements above.
    if (!canElementBeFocused) {
        return false;
    }

    // If keyboard interaction is disabled.
    if (element.getAttribute('tabindex') === '-1') {
        return false;
    }

    // If its disabled.
    if (
        (
            element instanceof HTMLButtonElement ||
            element instanceof HTMLInputElement ||
            element instanceof HTMLTextAreaElement ||
            element instanceof HTMLSelectElement
        )
        && element.disabled === true
    ) {
        return false;
    }

    // If its a hidden input.
    if (element instanceof HTMLInputElement && element.type === 'hidden') {
        return false;
    }

    // If its hidden.
    if (window.getComputedStyle(element).display === 'none') {
        return false;
    }

    return true;
}

/**
 * Gets the first focusable element, either the element its self or an element within.
 */
export const getFirstFocusableDescendant = (element: HTMLElement) => {
    if (isElementFocusable(element)) {
        return element;
    }

    const children = Array.from(element.querySelectorAll('a, button')) as HTMLElement[];

    return children.find((child) => (
        isElementFocusable(child)
    ));
}
