import React from 'react';

import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';
import formatPrice from '../../../../../../helpers/formatPrice';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import QuantityButton from '../QuantityButton/QuantityButton';
import ImageWithFallback from '../../../../../NextJs/ImageWithFallback/ImageWithFallback';

import styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
    openQuantitySelector: (args?: any) => void;
}

const DefaultBasketItem = ({ item, openQuantitySelector }: Props) => {
    const { deleteItems } = useManageBasketItems();

    // Deletes the basket item.
    const handleDeleteItem = () => deleteItems([{ id: item.id }]);

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={handleDeleteItem}
        >
            <div data-testid="default_basket_item" className="flex items-start gap-075">
                <ImageWithFallback
                    fallback="/images/pill.svg"
                    width={52}
                    height={52}
                    className={styles.image}
                    src={!Array.isArray(item.public) && item.public ? item.public.images.featuredUrl : null}
                    alt={item.treatment}
                />
                <div className="flex-grow space-y-025">
                    <div className='flex justify-between gap-100 items-start'>
                        <Typography as="h6" typeset="emphasis" size="070">{item.treatment}</Typography>
                        <DeleteCardOverlayTrigger
                            title={`Remove ${item.treatment} from your basket`}
                            className="text-status-error-default"
                        />
                    </div>
                    <div className="flex justify-between gap-100 items-center">
                        <QuantityButton onClick={openQuantitySelector} item={item} />
                        <Typography as="span" size="080">{formatPrice(item.total || 0)}</Typography>
                    </div>
                </div>
            </div>
        </DeleteCardOverlay>
    );
};

export default DefaultBasketItem;
