'use client';

import { createContext, useMemo, useContext } from 'react';

import { useDrillDownStackContext, useIsKeyboardFocusLogicDisabledContext } from '../../DrillDownContext/DrillDownContext';

interface Props {
    children: React.ReactNode;
    parentNavKey?: string;
    isTopLevelMenu?: boolean;
}

const IsPanelOpenContext = createContext(false);
const IsPanelInertContext = createContext(false);

export const DrillDownPanelProvider = ({
    parentNavKey,
    isTopLevelMenu,
    children,
}: Props) => {
    const drillDownStack = useDrillDownStackContext();
    const isKeyboardFocusLogicDisabled = useIsKeyboardFocusLogicDisabledContext();

    const isPanelOpen = useMemo(() => (
        drillDownStack.includes(parentNavKey || '')
    ), [drillDownStack, parentNavKey]);

    // If keyboard focus logic isn't disabled, it's not the top level panel, and the panels parent isn't in
    // // the drill-down stack. Typeof is there to fix  hydration issues.
    const isPanelInert = typeof window !== 'undefined'
        ? !isKeyboardFocusLogicDisabled && !isTopLevelMenu && !isPanelOpen
        : false;

    return (
        <IsPanelOpenContext.Provider value={isPanelOpen}>
            <IsPanelInertContext.Provider value={isPanelInert}>
                {children}
            </IsPanelInertContext.Provider>
        </IsPanelOpenContext.Provider>
    );
};

export const useIsPanelOpenContext = () => {
    const isPanelOpen = useContext(IsPanelOpenContext);
    if (isPanelOpen === undefined) {
        throw new Error('useIsPanelOpenContext must be used within a DrillDownPanelProvider.');
    }

    return isPanelOpen;
};

export const useIsPanelInertContext = () => {
    const isPanelInert = useContext(IsPanelInertContext);
    if (isPanelInert === undefined) {
        throw new Error('useIsPanelInertContext must be used within a DrillDownPanelProvider.');
    }

    return isPanelInert;
}
