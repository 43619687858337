'use client';

import React from 'react';

import { HeaderFields } from '../Header/Header';
import useCloseMegaMenuOnNavigation from './hooks/useCloseMegaMenuOnNavigation';
import { useAuthContext } from '../../../data/context/authContext';
import { useIsMainMenuOpenContext, useSetMainMenuOpenContext } from '../../../data/context/SiteNavigationContext';
import useShouldApplyDesktopStyles from './hooks/useShouldApplyDesktopStyles';
import useWindowSizeCheck from '../../../hooks/useWindowSizeCheck';
import useIsClient from '../../../hooks/useIsClient';

import DrillDown from '../DrillDown/DrillDown';

import styles from './MainNavigation.module.css';

type DrillDownProps = Pick<React.ComponentProps<typeof DrillDown>, 'treatmentCount' | 'conditionCount'>;

interface Props extends DrillDownProps {
    mainNavigation: HeaderFields['mainNavigation'];
};

const MainNavigation = ({ mainNavigation, treatmentCount, conditionCount }: Props) => {
    // This is for desktop.
    useCloseMegaMenuOnNavigation();

    const isClient = useIsClient();

    const [isGreaterThan] = useWindowSizeCheck(1024);

    const { isLoggedIn } = useAuthContext();

    const setNavigationOpen = useSetMainMenuOpenContext();
    const isNavigationOpen = useIsMainMenuOpenContext();

    const handleClose = () => {
        setNavigationOpen(false);
    }

    const shouldApplyDesktopStyles = useShouldApplyDesktopStyles();

    return (
        <DrillDown
            title="Menu"
            label="Main menu"
            className={shouldApplyDesktopStyles ? styles.nav : undefined}
            navItems={mainNavigation?.items || []}
            isOpen={isNavigationOpen}
            onClose={handleClose}
            treatmentCount={treatmentCount}
            conditionCount={conditionCount}
            isMainMenu
            isKeyboardFocusLogicDisabled={isGreaterThan}
            calloutButtonText={isClient && !isLoggedIn ? 'Sign In' : undefined}
            calloutButtonHref={isClient && !isLoggedIn ? '/login' : undefined}
        />
    );
};

export default MainNavigation;
